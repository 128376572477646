<template>
  <div class="badge"
       :class="dynamicClass"
       :style="computedStyle">
    <slot name="content">
      <div class="badge-content flex-middle flex-center flex" :class="contentGapClass">
        <icon-halo
          v-if="icon"
          :theme="theme"
          :halo-theme="contrastColor"
          size="1"
          halo-ratio="0.1"
          :icon="icon"
          @click="emitIconStartClick"></icon-halo>
        <slot name="default">
          <span v-if="typeof text === 'string'">
            {{ translate(text) }}
          </span>
          <span v-if="typeof text === 'number'">
            {{ text }}
          </span>
        </slot>
        <icon-halo
          v-if="iconEnd"
          :theme="theme"
          :halo-theme="contrastColor"
          size="1"
          halo-ratio="0.1"
          :icon="iconEnd"
          @click="emitIconEndClick"></icon-halo>
      </div>
    </slot>
  </div>
</template>

<script>
import badgeMixin from "@/client/extensions/mixins/baseComponents/etc/badge";

export default {
  mixins: [badgeMixin],
  props: {},
  emits: ["iconClick", "iconStartClick", "iconEndClick"],
  data: function () {
    return {};
  },
  computed: {},
  methods: {
    emitIconClick(side) {
      this.$emit("iconClick", { type: side });
    },
    emitIconStartClick() {
      this.emitIconClick("start");
      this.$emit("iconStartClick", { type: "start" });
    },
    emitIconEndClick() {
      this.emitIconClick("end");
      this.$emit("iconEndClick", { type: "end" });
    },
  },
};
</script>

<style scoped lang="scss"></style>

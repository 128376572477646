<template>
  <div class="icon-halo we-icon-halo" :style="haloComputedStyle">
    <i
      class="we-icon icon we-icon--halo icon--halo"
      :style="iconComputedStyle"
      :title="finalIconAlt" />
  </div>
</template>

<script>
import IconHaloMixin from "@/client/extensions/mixins/baseComponents/html/iconHalo.js";

export default {
  /**
   * Api:
   * icon - name of the icon file (no extension)
   * set - name of the folder set in the assets folder. default is based on config
   * alt - alt for the icon, uses a generic default, uses safeTranslate
   * ratio - size compared to current font size (em). default is slightly larger than text
   * height & width: overrides ratio, if provided
   * color: overrides color, default is currentcolor (css)
   */
  mixins: [IconHaloMixin],
  props: {},
  data: function () {
    return {};
  },

  computed: {
    haloComputedStyle() {
      return {
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        height: this.haloHeightProperty,
        width: this.haloWidthProperty,
        flex: this.haloFlexProperty,
        "min-height": this.haloHeightProperty,
        "min-width": this.haloWidthProperty,
        "border-radius": this.haloBorderRadiusProperty,
        "background-color": this.haloBackgroundColorProperty,
        border: `${this.borderThickness}px solid var(--c-${this.borderTheme}`,
      };
    },
    iconComputedStyle() {
      return {
        height: this.heightProperty,
        width: this.widthProperty,
        flex: this.flexProperty,
        "background-color": this.colorProperty,
        "-webkit-mask": this.finalIconMaskProperty,
        mask: this.finalIconMaskProperty,
        "-webkit-mask-size": "contain",
        "mask-size": "contain",
      };
    },
  },
};
</script>

<style scoped lang="scss">
/*
.icon-halo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: v-bind(haloHeightProperty);
  width: v-bind(haloWidthProperty);
  flex: v-bind(haloFlexProperty);
  min-height: v-bind(haloHeightProperty);
  min-width: v-bind(haloWidthProperty);
  border-radius: v-bind(haloBorderRadiusProperty);
  background-color: v-bind(haloBackgroundColorProperty);

  .we-icon {
    height: v-bind(heightProperty);
    width: v-bind(widthProperty);
    flex: v-bind(flexProperty);
    background-color: v-bind(colorProperty);
    -webkit-mask: v-bind(finalIconMaskProperty);
    mask: v-bind(finalIconMaskProperty);
    -webkit-mask-size: contain;
    mask-size: contain;
  }
}
*/
</style>

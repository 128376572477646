<template>
  <div class="i18n-phone-input flex relative" v-bind="noClassAttrs">
    <form-input
      v-model="prefix"
      type="select2"
      autocomplete="false"
      placeholder="core.form.i18nPhonePrefixPlaceholder"
      :list="intelPrefixes"
      :wrapper-margins="false"
      :auto-input-styleing="false"
      class="prefix-select">
    </form-input>

    <input class="number-input"
           v-model="number"
           style="flex: 100%"
           v-bind="inputAttrs" />
  </div>
</template>

<script>
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";
import i18nPhoneNumberInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/i18nPhoneNumberInput.js";

export default {
  mixins: [SimpleInputMixin, i18nPhoneNumberInputMixin],
};
</script>

<style lang="scss">
// NOTICE! non prefixed style! watch it!
.i18n-phone-input {
  display: flex;
  align-items: stretch;

  [dir="rtl"] & {
    flex-direction: row-reverse;
  }

  .dropdown-icon {
    $inset: calc(90px - var(--margin-m));
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-100%);
    inset-inline-start: $inset;

    [dir="rtl"] & {
      inset-inline-start: auto;
      inset-inline-end: $inset;
    }
  }

  .number-input {
    min-width: 0;
    padding-inline-start: 0.5em;
    padding-inline-end: 0.5em;
  }

  .prefix-select {
    appearance: none;
    border: none;
    background: var(--c-gray-1);
    direction: ltr;
    padding-inline-start: 1em;
    cursor: pointer;
    min-width: 9em;
    max-width: 9em;

    .field__element{
      padding-block: 0!important;
    }
    > .field__controls-wrapper {
      > .field__controls {
        border: none;
        border-inline-end: 1px solid var(--c-gray-2);
        background: transparent;
        border-radius: 0;

        .icon {
          inset-inline-end: 0.2em;
        }

        > .field__element {
          background-color: transparent;
          padding-top: 0.6em;
          padding-bottom: 0.6em;
          padding-inline-start: 1em;
          padding-inline-end: 1em;
          direction: ltr;
          max-width: 100%;
          min-width: 0;
        }
      }
    }

    .select2-dropdown {
      min-width: 250px;

      .filter-wrapper {
        [dir="rtl"] {
          direction: rtl;
        }
      }

      .field .field__element {
        max-width: 100%;
        min-width: 0;

        [dir="rtl"] & {
          direction: rtl;
        }
      }

      .options-wrapper {
        direction: ltr !important;
        text-align: left !important;

        .select2-item-icon {
          direction: ltr !important;
          text-align: left !important;
        }
      }
    }
  }
}
</style>

<template>
  <div class="flex width-expand" :class="wrapperClass">
    <form-button
      icon="minus"
      :slim="true"
      :disabled="value <= minimum ? true : false"
      :show-slot="false"
      @click="decrement()"></form-button>

    <input
      v-model.number="value"
      :value="value"
      v-bind="inputAttrs"
      class="width-expand padding-m-horizontal"
      @focusin="isFocused = true"
      @focusout="handleFocusOut" />

    <form-button
      icon="plus"
      :slim="true"
      :disabled="value >= maximum ? true : false"
      :show-slot="false"
      @click="increment()"></form-button>
  </div>
</template>

<script>
import unitsInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/unitsInput.js";

export default {
  mixins: [unitsInputMixin],
  props: {},
  data: function () {
    return {};
  },
  computed: {
    containerAttrs() {

    },
    wrapperClass() {
      let result = [""];
      result.push(this.class.replace("field__element", ""));
      return result;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">

</style>

<template>
  <input v-model="value" v-bind="inputAttrs" />
</template>

<script>
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";

export default {
  mixins: [SimpleInputMixin],
};
</script>

<style scoped lang="scss"></style>

<template>
  <div
    v-tooltip="tooltip"
    class="quick-action cursor-pointer animate__fadeIn animate--fast"
    :class="computedClass"
    @click="runCallback">
    <icon-halo
      class=""
      :icon="icon"
      :halo-theme="haloTheme"
      :theme="theme"
      :halo-ratio="haloRatio"
      :size="size"
      @click="runCallback"></icon-halo>
  </div>
</template>

<script>
import { isRef } from "vue";

export default {
  props: {
    // todo: position thingy
    callback: {
      type: Function,
      default: () => {},
    },
    tooltip: {
      type: [String, Object, Boolean],
      default: false,
    },
    theme: {
      type: String,
      default: "bg",
    },
    haloTheme: {
      type: String,
      default: "lead",
    },
    size: {
      type: Number,
      default: "1",
    },
    haloRatio: {
      type: [Number, String],
      default: "0.5",
    },
    icon: {
      type: String,
      default: "pencil",
    },
    /**
     * How does this "sit"
     * Values: inline, absolute-border absolute-outside absolute-inside,
     * absolute-outside-top, absolute-outside-diagonal, absolute-border-top
     */
    position: {
      type: String,
      default: "inline",
    },
    // position offset in px
    positionOffset: {
      type: Number,
      default: 0,
    },
    beforeStartEdit: {
      type: Function,
      default: () => {},
    },
  },
  emits: [["quick-action:activated"]],
  data: function () {
    return {};
  },
  computed: {
    computedClass() {
      const res = [];

      if (this.position === "inline") {
        res.push("inline-block");
      } else {
        res.push(this.position);
      }

      return res;
    },
    elementSizeEm() {
      return 1 * Number(this.size) + 1 * Number(this.haloRatio);
    },
    elementSizeCssUnit() {
      return this.elementSizeEm + "em";
    },
    elementHalfSizeCssUnit() {
      return this.elementSizeEm / 2 + "em";
    },
    elementSizeCssUnitNegative() {
      return "-" + this.elementSizeCssUnit;
    },
    elementHalfSizeCssUnitNegative() {
      return "-" + this.elementHalfSizeCssUnit;
    },
    offsetPx() {
      return this.positionOffset + "px";
    },
    isEnabled() {
      if (isRef(this.editor.isEnabled)) {
        return this.editor.isEnabled.value;
      } else {
        return this.editor.isEnabled;
      }
    },
  },
  methods: {
    runCallback() {
      if (this.callback) {
        this.callback(this);
      }

      this.$emit("quick-action:activated");
    },
  },
};
</script>

<style scoped lang="scss">
.quick-action {
  z-index: var(--z-top-1);

  &.inline-block {
    margin-inline-end: 1em;
  }
  &.absolute-outside-top {
    display: inline-block;
    position: absolute;
    top: calc(v-bind(elementSizeCssUnitNegative) - 0.75em + v-bind(offsetPx));
    inset-inline-start: 50%;
    margin-inline-start: v-bind(elementHalfSizeCssUnitNegative);
  }

  &.absolute-top {
    display: inline-block;
    position: absolute;
    top: calc(0.75em + v-bind(offsetPx));
    inset-inline-start: 50%;
    margin-inline-start: v-bind(elementHalfSizeCssUnitNegative);
  }

  &.absolute-outside-diagonal {
    display: inline-block;
    position: absolute;
    top: calc(v-bind(elementSizeCssUnitNegative) - 0.75em + v-bind(offsetPx));
    inset-inline-start: calc(
      v-bind(elementSizeCssUnitNegative) - 0.75em + v-bind(offsetPx)
    );
  }

  &.absolute-outside {
    display: inline-block;
    position: absolute;
    top: 0;
    inset-inline-start: calc(
      v-bind(elementSizeCssUnitNegative) - 0.75em + v-bind(offsetPx)
    );
  }

  &.absolute-inside {
    display: inline-block;
    position: absolute;
    top: calc(0.75em + v-bind(offsetPx));
    inset-inline-start: calc(0.75em + v-bind(offsetPx));
  }

  &.absolute-inside-inline-end {
    display: inline-block;
    position: absolute;
    top: calc(0.75em + v-bind(offsetPx));
    inset-inline-start: auto;
    inset-inline-end: calc(0.75em + v-bind(offsetPx));
  }

  &.absolute-border {
    display: inline-block;
    position: absolute;
    top: calc(v-bind(elementHalfSizeCssUnitNegative) + v-bind(offsetPx));
    inset-inline-start: calc(v-bind(elementHalfSizeCssUnitNegative) + v-bind(offsetPx));
  }

  &.absolute-border-top {
    display: inline-block;
    position: absolute;
    top: calc(v-bind(elementHalfSizeCssUnitNegative) + v-bind(offsetPx));
    inset-inline-start: 50%;
    margin-inline-start: v-bind(elementHalfSizeCssUnitNegative);
  }
}
</style>

<template>

  <vue-json-pretty
    class="ltr text-left"
    v-bind="$attrs"
    :data="value"
    :deep="deep"
    :show-length="true"
    :show-icon="true"
    :virtual="true"
    :height="height"
    :editable="true"
    editable-trigger="dblclick"
    @update:data="value = $event"></vue-json-pretty>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
  },
  props: {
    height: {
      type: Number,
      default: 400,
    },
    deep: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: [Object, String, Number, Array, Boolean],
    },
  },
  data: function () {
    return {};
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style scoped lang="scss"></style>

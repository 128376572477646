<template>
  <div class="content-blocks">
    <component
      :is="getBlockComponentName(block)"
      v-for="block of blocks"
      :key="getBlockUniqueId(block)"
      v-bind="{block}">
    </component>
  </div>
</template>

<script>
import contentBlocks from "@/client/extensions/mixins/baseComponents/html/contentBlocks";

export default {
  mixins: [contentBlocks],
};
</script>

<style scoped lang="scss"></style>

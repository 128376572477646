<template>
  <component
    :is="'default-notification-item'"
    :action="action"
    v-bind="$props"
    :notification="safeNotification">
  </component>
</template>

<script>

import notificationItem from "@/client/extensions/mixins/baseComponents/notificationItem";

export default {
  mixins: [notificationItem],
  props: {
    icon: {
      type: [String, Boolean],
      default: "bag-alt",
    },
  },
  data: function () {
    return {};
  },
  computed: {

  },
  methods: {
    getSafeNotificationData(notificationData) {
      let res = {};

      if (notificationData && typeof notificationData === "object") {
        res = { ...notificationData };
      }


      // example: make bogle
      if (!res.bogle) {
        res.bogle = "unknown";
      }

      //todo: 1. mixin for notification
      //todo: 2. action abstraction for notifications
      //todo: 3. intro layout
      //todo: 4. standard layout
      //todo: 5. notification page - full styling
      //todo: 6. notification page - mark as read automatically
      //todo: 7. document

      // remaining after: emails, notification list widget

      return res;
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <large-option v-bind="$props"
                @large-option:selected="$emit('large-option:selected', value)">
    <template #endText>

      <span v-if="showPrice">
         {{
          price && price != 0
            ? translateNumber(price, "currency", {
              currency: currency,
            })
            : translate("ecommerce.general.free")
        }}
      </span>

    </template>
  </large-option>
</template>

<script>
// todo: make this large select fields generic, use for shipping and payment methods
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";

export default {
  mixins: [SimpleInputMixin],
  props: {
    title: {
      type: [Boolean, String],
      default: false,
    },
    text: {
      type: [Boolean, String],
      default: false,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    price: {
      type: [Boolean, Number],
      default: false,
    },
    currency: {
      type: [Boolean, String],
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
  },
  emits: ["large-option:selected"],
};
</script>

<style scoped lang="scss">
.large-option {
  border: 1px solid var(--c-gray);
  margin-bottom: var(--margin-m);
  cursor: pointer;
  background: var(--c-gray-1);
  border-radius: var(--border-radius-s);

  &:last-of-type {
    margin-bottom: 0;
  }

  &.selected {
    border: 1px solid var(--c-lead);
  }
}
</style>

<template>
  <fieldset class="form__fieldset active" :class="computedClass">
    <slot name="title">
      <h4 v-if="title" class="form__fieldset-title flex flex-top">
        <icon v-if="icon" :icon="icon"></icon>
        {{ translateTitleCase(title) }}
      </h4>
    </slot>

    <div v-responsive-classes class="form__fieldset-content" :class="contentClass">
      <slot></slot>
    </div>
  </fieldset>
</template>

<script>
import formFieldSetMixin from "@/client/extensions/mixins/baseComponents/form/formFieldSet.js";

export default {
  mixins: [formFieldSetMixin],
  props: {
    maxFieldsPerRow: {
      type: [Number, String],
      default: 1,
    },
    appearance: {
      type: String,
      default: "none",
    },
  },
  data: function () {
    return {};
  },
  computed: {
    contentClass() {
      let result = [];

      if (+this.maxFieldsPerRow > 1) {
        result.push("max-" + this.maxFieldsPerRow + "-per-row");
      }
      return result;
    },
    computedClass() {
      let result = [];

      result.push("appearance-" + this.appearance);
      return result;
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="select-media-container">
    <div class="flex gap-m">
      <div
        class="preview padding-s flex flex-stretch border-thin-gray-2 cursor-pointer"
        style="height: 100px; width: 150px"
        @click.stop.prevent="startSelectFileProcess">
        <img
          v-if="currentMediaUrl"
          class="object-cover max-size-parent width-expand"
          :src="currentMediaUrl" />
        <div
          v-else
          class="image-placeholder flex flex-center flex-middle bg-gray-4 width-expand">
          <icon size="3" icon="image" theme="bg"></icon>
        </div>
      </div>
      <div class="controls" :style="isLoading ? `opacity:0.6;pointer-events:none;` : ``">
        <div class="upload-control flex flex-column flex-between" style="height: 100%">
          <form-button
            v-if="value"
            theme="alt"
            text="core.form.quickMedia.change"
            icon="refresh"
            @click.stop.prevent="startSelectFileProcess"></form-button>
          <form-button
            v-if="value"
            theme="danger"
            text="core.form.quickMedia.clear"
            icon="x"
            @click.stop.prevent="value = null"></form-button>
          <form-button
            v-if="!value"
            theme="success"
            text="core.form.quickMedia.uploadCta"
            icon="cloud-upload"
            @click.stop.prevent="startSelectFileProcess"></form-button>
        </div>
        <file-input
          ref="fileUploadField"
          v-model="uploadFiles"
          class="hidden"
          multiple="false"
          :accept="accept"
          label="file upload" />
      </div>
    </div>
    <div class="message-container" style="min-height: 20px">
      <div class="message flex flex-middle flex-start gap-m margin-m-top">
        <spinner v-if="isLoading"></spinner>
        <icon-text icon="warning-triangle" v-if="internalErrorMessage" class="c-danger">{{
          internalErrorMessage
        }}</icon-text>
        <icon-text icon="check" v-if="internalSuccessMessage" class="c-success">{{
          internalSuccessMessage
        }}</icon-text>
        <span v-if="internalMessage">{{ internalMessage }}</span>
      </div>
    </div>

    <canvas ref="resizeCanvas" style="display: none"></canvas>
  </div>
</template>

<script>
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";
import useMediaManager from "@/client/extensions/composition/useMediaManager";
import asyncOperations from "@/client/extensions/composition/asyncOperations";

export default {
  mixins: [SimpleInputMixin],
  props: {
    accept: {
        type: String,
        default: "*"
    },
    imagesOnly: {
      type: Boolean,
      default: false,
    },
    valueField: {
      type: String,
      default: "id",
    },
    maxImageWidth: {
      type: Number,
      default: 1920,
    },
    maxImageHeight: {
      type: Number,
      default: 1200,
    },
  },
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    const mediaManager = useMediaManager(props, {});
    return { asyncOps, asyncOpsReady, asyncStatus, mediaManager };
  },
  data() {
    return {
      uploadedMedia : false,
      mediaItems: this.mediaManager.items,
      mediaUrl: false,
      uploadFiles: false,
      isLoading: false,
      internalErrorMessage: false,
      internalSuccessMessage: false,
      internalMessage: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("field:changed", value);
        this.$emit("update:modelValue", value);
      },
    },
    currentMediaUrl () {
      if (this.currentMedia) {
        return this.currentMedia.thumbUrl;
      }

      if (this.uploadedMedia) {
        return this.uploadedMedia.thumbUrl;
      }

      if (this.mediaUrl) {
        return this.mediaUrl
      }

      if (this.value && this.value != '') {
        return this.value;
      }

      return false
    },
    currentMedia() {
      if (this.mediaItems && this.mediaItems.length > 0) {
        return this.mediaItems[0];
      }

      return false;
    },
  },
  watch: {
    value: {
      async handler(newVal, oldVal) {

        if (this.uploadedMedia) {
          this.mediaUrl = this.uploadedMedia.thumbUrl;
        }

        if (!newVal) {
          return true;
        }
        // todo: get media URL either from plain value or from media manager
        this.mediaManager.setLimit(1);
        this.mediaManager.setFilter(this.valueField, newVal);
        await  this.mediaManager.refreshListItems();

        if (this.valueField === 'url' || this.valueField === 'thumbUrl') {
          this.mediaUrl = newVal;
        }
        return newVal;
      },
      deep: true,
      immediate: true,
    },
    uploadFiles(newVal) {
      if (!newVal || newVal.length < 1) {
        return true;
      }
      this.uploadFilesByState();
    },
  },
  methods: {
    startSelectFileProcess() {
      try {
        this.$refs.fileUploadField.promptFileSelection();
      } catch (e) {
        warn("failed to focus file input", e);
      }
    },
    async enforceFileSize(file) {
      const canvas = this.$refs["resizeCanvas"];
      const maxWidth = this.maxImageWidth;
      const maxHeight = this.maxImageHeight;

      let resizeOnCanvas = (img) => {
        let { width, height } = img;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        // Create a canvas element
        const canvas = this.$refs["resizeCanvas"];
        const ctx = canvas.getContext("2d");

        // Resize the canvas to the new dimensions
        canvas.width = width;
        canvas.height = height;

        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0, width, height);
        return true;
      };
      let blobToFile = (blob) => {
        return new File([blob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });
      };
      let imageLoadHandler = (img, resolve) => {
        resizeOnCanvas(img);
        // Convert the canvas content to a Blob object
        canvas.toBlob((blob) => {
          resolve(blobToFile(blob));
        }, file.type);
      };

      let result = await new Promise((resolve) => {
        let img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => imageLoadHandler(img, resolve);
      });

      return result;
    },
    async uploadFilesByState() {
      // reset UI
      this.isLoading = false;
      let hasError = false;
      this.internalMessage = false;
      this.internalErrorMessage = false;
      this.internalSuccessMessage = false;

      // validation and ui for error
      for (const file of this.uploadFiles) {
        if (file.size / 1000000 > 50) {
          this.$s.ui.notification(
            "core.form.quickMedia.extremelyLargeFileError",
            "error",
            {
              contentParams: { maxSizeMB: config.mediaManager.maxFileSizeMB },
            }
          );
          this.internalErrorMessage = this.t(
            "core.form.quickMedia.extremelyLargeFileError"
          );
          return false;
        }

        let isImage = file.type.startsWith("image/");
        if (!isImage && this.imagesOnly) {
          this.$s.ui.notification("core.form.quickMedia.invalidFormat", "error");
          this.internalErrorMessage = this.t("core.form.quickMedia.invalidFormat");
          return false;
        }
      }

      // file resize and upload, with ui
      this.isLoading = true;
      let uploadResult;
      for (const file of this.uploadFiles) {
        let isImage = file.type.startsWith("image/");
        this.internalMessage = this.t("core.form.quickMedia.processingOngoing");
        let finalFile;
        if (isImage) {
          finalFile = await this.enforceFileSize(file);
        } else {
          finalFile = file;
        }

        this.internalMessage = this.t("core.form.quickMedia.uploadInProgress");
        uploadResult = await this.mediaManager.upload(finalFile, { data: {} });

        if (!uploadResult) {
          hasError = true;
        }
      }

      // error ui
      if (hasError) {
        this.$s.ui.notification("media.manager.uploadErrorOccurred", "error");
        this.isLoading = false;
        this.internalMessage = false;
        this.internalErrorMessage = this.t("core.form.quickMedia.uploadError");
        return;
      }

      // success ui
      this.internalMessage = false;
      this.internalSuccessMessage = this.t("core.form.quickMedia.uploadSuccess");
      this.isLoading = false;
      this.value = uploadResult[this.valueField];
      this.uploadedMedia = uploadResult;
    },
  },
};
</script>

<style scoped lang="scss"></style>

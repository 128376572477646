<template>
  <div class="large-options">
    <component
      :is="optionComponent"
      v-for="option in list"
      :selected="value === option.value"
      v-bind="{ ...option,...optionComponentProps,}"
      @large-option:selected="value = option.value">
    </component>
  </div>
</template>

<script>
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";

export default {
  mixins: [SimpleInputMixin],
  props: {
    title: {
      type: [Boolean, String],
      default: false,
    },
    description: {
      type: [Boolean, String],
      default: false,
    },
    endText: {
      type: [Boolean, String],
      default: false,
    },
    list: {
      type: Array,
      default: [],
    },
    optionComponent: {
      type: String,
      default: "LargeOption",
    },
    optionComponentProps: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
.large-option {
  border: 1px solid var(--c-gray);
  margin-bottom: var(--margin-m);
  cursor: pointer;
  background: var(--c-gray-1);
  border-radius: var(--border-radius-s);

  &:last-of-type {
    margin-bottom: 0;
  }

  &.selected {
    border: 1px solid var(--c-lead);
  }
}
</style>

import { reactive } from "vue";

export default {
  props: {
    modelValue: {},
    type: {},
    list: {}, // prevent inheritence of list="" on element
  },
  data: function () {
    return {
      isFocused: false,
      test: null,
    };
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    inputAttrs() {
      let attrs = { ...this.$attrs };
      delete attrs.list;
      delete attrs.class;
      return attrs;
    },
    computedInputType() {
      return this.isFocused ? "color" : "text";
    },
  },
  methods: {},
};

<template>
  <div class="spoiler">
    <div class="content-wrapper" :style="{ 'max-height': currentContentMaxHeight }">
      <div ref="content" class="content">
        <slot name="default"></slot>
      </div>
    </div>

    <slot name="controls"
          v-bind="{ isExpanded, spoiler: this, component: this }">
      <div class="controls flex flex-end"
           v-if="shouldShowControls"
           :style="controlsStyle"
           :class="`c-${controlsTheme}`">
        <a
          v-if="isExpanded"
          class="underline"
          :class="`c-${controlsTheme}`"
          href="#"
          @click.prevent.stop="contract">
          <icon-text icon-position="end" icon="chevron-up">פחות</icon-text>
        </a>
        <a
          v-if="!isExpanded"
          class="underline"
          :class="`c-${controlsTheme}`"
          href="#"
          @click.prevent.stop="expand"
          ><icon-text icon-position="end" icon="chevron-inline-end">עוד</icon-text>
        </a>
      </div>
    </slot>
  </div>
</template>

<script>
import SpoilerMixin from '@/client/extensions/mixins/baseComponents/html/spoiler.js';

export default {
  mixins: [SpoilerMixin],
};
</script>

<style scoped lang="scss">
.spoiler {
  .content-wrapper {
    overflow: hidden;
    transition: max-height 200ms ease;
  }
}
</style>

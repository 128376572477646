<template>
  <div class="grid-column">
    <slot></slot>
  </div>
</template>

<script>
export default {
  inject: ["columns", "grow", "gutter", "gutterS", "gutterM", "gutterL"],
  props: {
    span: {
      type: [Number, String],
      default: 4, // or "fit"
    },
    spanS: {
      type: [Number, String, undefined],
      default: undefined,
    },
    spanM: {
      type: [Number, String, undefined],
      default: undefined,
    },
    spanL: {
      type: [Number, String, undefined],
      default: undefined,
    },
    order: {
      type: Number,
      default: 100,
    },
    offset: {
      type: Number,
      default: 0,
    },
    offsetS: {
      type: [Number, undefined],
      default: undefined,
    },
    offsetM: {
      type: [Number, undefined],
      default: undefined,
    },
    offsetL: {
      type: [Number, undefined],
      default: undefined,
    },
    orderS: {
      type: [Number, undefined],
      default: undefined,
    },
    orderM: {
      type: [Number, undefined],
      default: undefined,
    },
    orderL: {
      type: [Number, undefined],
      default: undefined,
    },
    orderXl: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data: function () {
    return {
      //"max", "2xl"
      modifierMap: ["xl", "l", "m", "s", "xs"],
    };
  },
  computed: {
    computedFlex() {
      return this.computeFlex();
    },
    computedFlexS() {
      return this.computeFlex("s");
    },
    computedFlexM() {
      return this.computeFlex("m");
    },
    computedFlexL() {
      return this.computeFlex("l");
    },
    computedFlexXl() {
      return this.computeFlex("l");
    },
    computedPadding() {
      return this.computePadding();
    },
    computedPaddingS() {
      return this.computePadding("s");
    },
    computedPaddingM() {
      return this.computePadding("m");
    },
    computedPaddingL() {
      return this.computePadding("l");
    },
    computedPaddingXl() {
      return this.computePadding("l");
    },
    computedOrder() {
      return this.computeOrder();
    },
    computedOrderS() {
      return this.computeOrder("s");
    },
    computedOrderM() {
      return this.computeOrder("m");
    },
    computedOrderL() {
      return this.computeOrder("l");
    },
    computedOrderXl() {
      return this.computeOrder("xl");
    },
  },
  methods: {
    /**
     * Get a proprty which has mobile modifiers based on availability (desktop first)
     * @param name
     * @param modifier
     */
    getPropByPriority(name, modifier = undefined) {
      if (!modifier) {
        modifier = this.modifierMap[0];
      }
      // start with the default
      let baseName = name;
      let value = this[name];
      modifier = utilities.ucFirst(modifier);
      // iterate down to MODIFIER, overriding when found
      for (const possibleModifier of this.modifierMap) {
        let propName = baseName + utilities.ucFirst(possibleModifier);
        if (this[propName] !== undefined) {
          value = this[propName];
        }

        if (utilities.ucFirst(possibleModifier) == modifier) {
          break;
        }
      }
      // return the result
      return value;
    },
    computeFlex(breakPoint) {
      let targetSpan = this.getPropByPriority("span", breakPoint);

      if (targetSpan === "fit") {
        return `auto`;
      }
      let colSizeRatio = 100 / parseInt(this.columns);
      let ourSize = parseInt(targetSpan) * colSizeRatio;

      if (this.grow) {
        return `${this.columns} ${this.columns} ${ourSize}%`;
      } else {
        return `0 0 ${ourSize}%`;
      }
    },
    computePadding(breakPoint) {
      let targetGutter = this.getPropByPriority("gutter", breakPoint);
      if (typeof this.gutter === "string") {
        let safeGutter = `var(--margin-${targetGutter})`;
        let colGutter = `calc(${safeGutter} / 2)`;
        return `${colGutter}`;
      } else {
        let safeGutter = parseInt(targetGutter);
        let colGutter = safeGutter / 2;
        return `${colGutter}px`;
      }
    },
    computeOrder(breakpoint) {
      let targetOrder = this.getPropByPriority("order", breakpoint);
      return targetOrder;
    },
  },
};
</script>

<style scoped lang="scss">
.grid-column {
  // border: 1px solid red;
  flex: v-bind(computedFlex);
  padding: v-bind(computedPadding);
  order: v-bind(computedOrder);

  //TODO: temp
  @media (max-width: 1300px) {
    flex: v-bind(computedFlexXl);
    padding: v-bind(computedPaddingXl);
    order: v-bind(computedOrderXl);
  }

  @media (max-width: 1024px) {
    flex: v-bind(computedFlexL);
    padding: v-bind(computedPaddingL);
    order: v-bind(computedOrderL);
  }

  @media (max-width: 767px) {
    flex: v-bind(computedFlexM);
    padding: v-bind(computedPaddingM);
    order: v-bind(computedOrderM);
  }

  @media (max-width: 499px) {
    flex: v-bind(computedFlexS);
    padding: v-bind(computedPaddingS);
    order: v-bind(computedOrderS);
  }
}

//span, offset, order, responsive, grow
</style>

<template>
  <div
    v-if="notification && layout === 'default'"
    class=" notification-item notification-item--default-layout"
    :class="{'notification-item--is-read' : notification.isRead}"
    v-bind="$attrs"
    :index="index">
    <div class="notification__header flex flex-middle gap-m margin-m-bottom">
      <div class="notification__header-figure relative">
        <icon-halo theme="bg" halo-theme="lead" :icon="icon"></icon-halo>
        <badge theme="danger"
               v-if="!notification.isRead"
               :border="2"
               :border-theme="'bg'"
               :round="true"
               style="
                 position: absolute;
                 top: -3px;
                 inset-inline-start:-3px;
                 height: 12px;
                 width: 12px;"
        ></badge>
      </div>
      <div class="notification__header-content">
        <h6 class="margin-bottom-remove text-bold text-m">
          {{
            translate(`notifications.types.${notification.type}.title`, translationParams)
          }}
        </h6>
        <span class="block text-xs c-gray-4 text-bold">
          {{ translateDate(notification.createdAt, "timeAgo") }}
        </span>
      </div>
    </div>

    <p class="block" :class="{'c-gray-4' : notification.isRead}">
      <slot>
        {{
          translate(`notifications.types.${notification.type}.content`, {
            ...translationParams,
          })
        }}</slot>
    </p>
    <div
      v-if="action || $slots['action']"
      class="notification-action margin-m-top width-expand flex-end">
      <slot name="action">
        <component
          :is="ctaComponent"
          v-bind="ctaConfig"
          @click="executeNotificationAction()"></component>
      </slot>
    </div>
  </div>

  <timeline-item
    v-if="notification && layout === 'timeline'"
    v-bind="$attrs"
    :icon="icon"
    :index="index"
    :date-time="notification.createdAt"
    :title="'Notification: ' + notification.type">
    <template #title-text>
      {{ translate(`notifications.types.${notification.type}.title`, translationParams) }}
    </template>
    <template #content>
      <div class="timeline-item__main-content">
        <p>
          <slot>
            {{
              translate(`notifications.types.${notification.type}.content`, {
                ...translationParams,
              })
            }}</slot
          >
        </p>
        <div
          v-if="action || $slots['action']"
          class="notification-action margin-m-top width-expand flex-end">
          <slot name="action">
            <component
              :is="ctaComponent"
              v-bind="ctaConfig"
              @click="executeNotificationAction()"></component>
          </slot>
        </div>
      </div>
    </template>
  </timeline-item>

  <div
    v-if="notification && layout === 'intro'"
    class=" notification-item notification-item--intro-layout"
    :class="{'notification-item--is-read' : notification.isRead}"
    v-bind="$attrs"
    :index="index">
    <div class="notification__header flex flex-middle gap-s margin-m-bottom">
      <div class="notification__header-figure relative">
        <icon-halo theme="bg" halo-theme="lead" :icon="icon" v-if="icon" :size="0.8"></icon-halo>
        <badge theme="danger"
               v-if="!notification.isRead"
               :border="1"
               :border-theme="'bg'"
               :round="true"
               style="
                 position: absolute;
                 top: -2px;
                 inset-inline-start:-2px;
                 height: 10px;
                 width: 10px;"
        ></badge>
      </div>
      <div class="notification__header-content flex gap-s flex-between flex-middle flex-center">
        <h6 class="margin-bottom-remove text-bold text-s">
          {{
            translate(`notifications.types.${notification.type}.title`, translationParams)
          }}
        </h6>
      </div>
    </div>

    <p class="notification-text block text-s" :class="{'c-gray-4' : notification.isRead}">
      <slot>
        {{
          translate(`notifications.types.${notification.type}.content`, {
            ...translationParams,
          })
        }}</slot>
    </p>
    <div class="flex flex-between flex-middle gap-m margin-m-top ">
        <span class="block text-min c-gray-4 text-bold" style="flex: 150px;">
         {{ translateDate(notification.createdAt, "timeAgo") }}
        </span>
        <div
        v-if="action || $slots['action']"
        class="notification-action width-expand flex-end flex">
        <slot name="action">
          <component
            :is="ctaComponent"
            v-bind="ctaConfig"
            @click="executeNotificationAction()"></component>
        </slot>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    layout: {
      type: String,
      default: "default",
    },
    notification: {
      type: [Object, Boolean],
      default: false,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    action: {
      type: [Object, Boolean],
      default: false,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    translationParams() {
      let res = { ...this.notification };
      if (this.notification.data && typeof this.notification.data === "object") {
        res = { ...this.notification, ...this.notification.data };
      }
      return res;
    },
    ctaComponent() {
      if (this.action?.ctaComponent) {
        return this.action?.ctaComponent;
      }

      return "form-button";
    },
    ctaConfig() {
      if (this.action?.ctaProps) {
        return this.action.ctaProps;
      }

      return {};
    },
  },
  methods: {
    executeNotificationAction() {
      if (!this.action || typeof this.action !== "object") {
        return true;
      }

      if (this.action?.type === "callback") {
        try {
          this.action.handler();
        } catch (e) {
          debug("failed to run notification handler", 2, {
            notification: this.notification,
            action: this.action,
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">

.notification-item--default-layout {
  color: var(--c-text);
  &.notification-item--is-read {
    color: var(--c-gray-4);
  }
}

.notification-item--intro-layout {
  color: var(--c-text);
  &.notification-item--is-read {
    color: var(--c-gray-4);
  }

  .notification-text{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

  }
}
</style>

<template>
  <input v-model="value"
         v-bind="inputAttrs"
         @focusin="isFocused = true"
         @focusout="handleFocusOut"
  />
</template>

<script>
import PriceInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/priceInput.js";

export default {
  mixins: [PriceInputMixin],
};
</script>

<style scoped lang="scss"></style>

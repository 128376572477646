<template>
  <div class="relative">
    <div class="country-image-wrapper" @click="openLocaleDropdown">
      <div
        class="country-image"
        :style="`background-image: url('${countryIconUrl}');`"></div>
    </div>

    <div
      ref="localeDropdown"
      v-click-outside="{ handler: closeLocaleDropdown }"
      v-dropdown="{ opened: isLocaleDropdownOpened, applyAnimations: true }"
      class="dropdown locale-dropdown relative"
      @dropdown:closed="isLocaleDropdownOpened = false">
      <div
        v-for="locale of switchableLocales"
        class="country-image-wrapper"
        @click="setLocale(locale.tag)">
        <div
          class="country-image"
          :style="`background-image: url('${getCountryIconUrl(locale.tag)}');`"></div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from "@/client/extensions/mixins/baseComponents/etc/LanguageSwitcher";

export default {
  props: {},
  mixins: [LanguageSwitcher],
  data: function () {

    return {

    };
  },
  computed: {

  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
.country-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: calc(var(--margin-m) * 0.6);
  background-color: var(--c-bg-5);
  border-radius: var(--border-radius-round);
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 200ms ease;

  &:hover {
    border-color: var(--c-inverse);
    background-color: var(--c-bg-4);
    box-shadow: var(--box-shadow-2);
  }

  .country-image {
    width: 100%;
    height: 100%;
    flex: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}

.dropdown {
  border-radius: var(--border-radius);
  width: calc(100% + 2 * var(--margin-m));
  inset-inline-start: calc(-1 * var(--margin-m));
}
</style>

<template>
  <div
    class="editor-wrapper relative field__controls"
    v-bind="$attrs"
    @focusin="isFocused = true"
    @focusout="isFocused = false">
    <!--
    {{ getLocale() }}
    <form-button @click="setLocale('he-IL')">HE IL</form-button>
    <form-button @click="setLocale('en-US')">EN US</form-button>
    value:
    <pre>{{ value }}</pre>
    <br />
-->
    <!--
    <div
      v-if="isEmpty && ! isFocused"
      style="pointer-events: none; margin-top: 8px; height: 0; overflow: visible"
      class="placeholder absolute">
      <span>{{ finalPlaceholder }}</span>
    </div>-->
    <div
      :id="editorUUID"
      style="max-width: 650px"
      @click="saveContentToState"
      @keyup="saveContentToState"
      @focusout="saveContentToState"></div>
  </div>
</template>

<script>
import editorInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/editorInput.js";
import { watchEffect, watch, ref } from "vue";

export default {
  mixins: [editorInputMixin],
  props: {
    placholder: {
      type: String,
      default: "core.form.editor.defaultPlaceholder",
    },
  },
  setup() {
    let editor = ref(null);
    let isEditorReady = new Promise((resolve) => {
      watchEffect(() => {
        if (editor.value) {
          resolve();
        }
      });
    });

    return { editor, isEditorReady };
  },
  data: function () {
    const vm = this;

    return {
      creatingEditor: false,
      isFocused: false,
      locked: false,
      editorUUID: "editor-" + utilities.getUniqueNumber() + "-" + Date.now(),
      changeDebounce: false,
      clearChangeDebounce: false,
      innerEditorReadyPromise: new Promise((fulfil, reject) => {
        vm.resolveEditorReady = fulfil;
      }),
      debouncedInitEditor: utilities.debounce(() => {
        this.initEditor();
      }, 20),
    };
  },
  computed: {
    isEmpty() {
      return (
        !this.value ||
        this.value === "" ||
        typeof this.value !== "object" ||
        !this.value.blocks ||
        this.value.blocks.length < 1
      );
    },
    finalPlaceholder() {
      return this.inputAttrs.placeholder && this.inputAttrs.placeholder != ""
        ? this.translate(this.inputAttrs.placeholder)
        : this.translate("core.form.editor.defaultPlaceholder");
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    localeSlug() {
      return this.$store.getters["locale/slug"];
    },
  },

  watch: {
    modelValue: {
      async handler(newVal, oldVal) {
        if (this.locked) {
          return true;
        }

        await this.isEditorReady;
        await this.destroyEditor();
        await utilities.wait(100);
        this.$nextTick(() => {
          if (this.locked) {
            return true;
          }

          this.debouncedInitEditor();
        });
      },
      immediate: true,
      deep: true,
    },
    async localeSlug(newVal) {
      await this.destroyEditor();
      await utilities.wait(100);
      this.$nextTick(() => {
        this.debouncedInitEditor();
      });
    },
  },
  mounted() {
    if (utilities.isSSR()) {
      return;
    }

    this.debouncedInitEditor();
  },
  beforeUnmount() {
    if (utilities.isSSR()) {
      return;
    }

    this.destroyEditor();
  },
  methods: {
    async destroyEditor() {
      if (utilities.isSSR()) {
        return true;
      }

      if (this.clearChangeDebounce) {
        this.clearChangeDebounce();
      }

      await utilities.wait(10);
      try {
        await this.editor.destroy();
      } catch (e) {}

      return true;
    },
    /**
     *
     * @param data editor data or array of blocks
     * @returns {Promise<bool>}
     */
    async setEditorData(data) {
      notice("notice: setEditorData doesnt work properly.");
      if (this.locked) {
        return true;
      }
      if (!this.editor) {
        await this.isEditorReady;
      }

      let blocks = false;
      if (data && typeof data === "object" && data.blocks) {
        blocks = data.blocks;
      } else {
        blocks = data;
      }

      if (!blocks || !Array.isArray(blocks)) {
        warn(
          'can not set blocks, invalid input. must be array of blocks or object with "blocks" array property',
          data
        );
        return false;
      }
      await this.editor.clear();
      for (const block of blocks) {
        await this.editor.blocks.insert(block.type, block.data);
      }
      return true;
    },
    // TODO:
    // 4. content render component framework (component and sub components)
    // 5. add several generic blocks
    // 6. add saffron media block
    async saveContentToState() {
      if (!this.editor) {
        return false;
      }

      if (!this.changeDebounce) {
        const debounceObject = utilities.debounce(
          async () => {
            this.lastInnerValue = await this.editor.save();
            this.locked = true;
            this.$emit("update:modelValue", this.lastInnerValue);
            this.$nextTick(() => {
              this.locked = false;
            });
          },
          200,
          { returnType: "all" }
        );

        this.changeDebounce = debounceObject.debounce;
        this.clearChangeDebounce = debounceObject.clear;
      }
      this.changeDebounce();
    },
    async setEditorValue() {},
    async initEditor() {
      if (utilities.isSSR()) {
        return;
      }

      if (this.creatingEditor) {
        return false;
      }

      this.locked = true;
      this.creatingEditor = true;
      let deps  = await Promise.all([
        await require("@editorjs/editorjs"),
        await require("editorjs-drag-drop"),
        await require("@editorjs/header"),
        await require("@editorjs/list"),
        await require("editorjs-hyperlink"),
      ]);

      deps.forEach((item, index) => {
        if (item.default) {
          deps[index] = item.default;
        }
      });

      let [EditorJS, DragDrop, HeaderTool, ListTool, HyperLink] = deps;
      let editor = new EditorJS({
        holder: this.editorUUID,
        placeholder: this.finalPlaceholder,
        logLevel: "WARN",
        data: this.value,
        tools: {
          header: HeaderTool,
          list: ListTool,
          hyperlink: {
            class: HyperLink,
            config: {
              target: "_blank",
              availableTargets: ["_blank", "_self"],
              availableRels: ["author", "noreferrer"],
              validate: false,
            },
          },
        },
        i18n: {
          direction: this.isLanguageRtl() ? "rtl" : "ltr",
          messages: {
            ui: {
              // Translations of internal UI components of the editor.js core
            },
            toolNames: {
              // Section for translation Tool Names: both block and inline tools
            },
            tools: {
              // Section for passing translations to the external tools classes
              // The first-level keys of this object should be equal of keys ot the 'tools' property of EditorConfig
            },
            blockTunes: {
              // Section allows to translate Block Tunes
            },
          },
        },
        onReady: () => {
          try {
            new DragDrop(editor);
          } catch (e) {}
        },
      });
      await editor.isReady;
      this.editor = editor;
      this.resolveEditorReady();
      this.locked = false;
      this.creatingEditor = false;
    },
  },
};
</script>

<style lang="scss">
.codex-editor .ce-toolbar__actions {
  // right: auto;
  // inset-inline-start: -5px;
}
.codex-editor__redactor {
  padding-bottom: 100px !important;
  > .ce-block {
    padding-left: 60px;
  }
}

.ce-toolbar__content .ce-toolbar__actions {
  inset-inline-start: 0;
  inset-inline-end: auto;
}

.ce-inline-tool-hyperlink--button {
}
</style>

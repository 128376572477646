<template>

  <h1 v-bind="$attrs" v-html="blockHtml" v-if="level === 1"></h1>
  <h2 v-bind="$attrs" v-html="blockHtml" v-if="level === 2"></h2>
  <h3 v-bind="$attrs" v-html="blockHtml" v-if="level === 3"></h3>
  <h4 v-bind="$attrs" v-html="blockHtml" v-if="level === 4"></h4>
  <h5 v-bind="$attrs" v-html="blockHtml" v-if="level === 5"></h5>
  <h6 v-bind="$attrs" v-html="blockHtml" v-if="level === 6"></h6>
</template>

<script>
import contentBlock from "@/client/extensions/mixins/baseComponents/html/contentBlock";

export default {
  mixins: [contentBlock],
  props: {

  },
  data: function () {
    return {};
  },
  computed: {
    blockHtml() {
      return this?.block?.data?.text ? this.block.data.text : '';
    },
    level() {
      return this?.block?.data?.level ? this?.block?.data?.level : 3;
    }
  },
};
</script>

<style scoped lang="scss"></style>

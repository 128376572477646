<template>
  <div class="flex gap-s" v-bind="inputAttrs">
    <icon
      class="star-rating-icon "
      :class="{'cursor-pointer': !isDisabled}"
      :size="iconSize"
      :icon="stars >= i ? 'star-fill' : 'star'"
      :theme="stars >= i ? 'lead' : 'gray'"
      @click="updateRating(i)"
      v-for="i in 5" />
  </div>
</template>

<script>
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";

export default {
  mixins: [SimpleInputMixin],
  props: {
    disabled: {
      type: [Boolean, String],
      default: false,
    },
    iconSize: {
      type: Number,
      default: 10
    },
  },
  computed: {
    stars: {
      get() {
        if ( ! this.value) {
          return 0
        }
        if (typeof this.value !== 'number' && typeof this.value !== 'string') {
          return 0;
        }

        let baseValue  = parseInt(this.value);
        return Math.floor(baseValue/2);
      },
      set(val) {
        this.value = val*2;
      },
    },
    isDisabled() {
      if (this.disabled === "1" || this.disabled === 1 || this.disabled === "true" || this.disabled === true) {
        return true;
      }

      return false;
    }
  },
  methods: {
    updateRating(val) {
      if ( ! this.isDisabled) {
        this.stars = val;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>

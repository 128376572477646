<template>
  <div class="we-theme-settings-container">
    <div class="controls-container" style="">
      <div class="toggle-container" :class="{ 'margin-2xl-bottom': isExpanded }">
        <form-button
          v-show="!isExpanded"
          class="animate__fadeIn animate__faster"
          icon="chevron-inline-end"
          text="theme.themeSettings.expand"
          @click.stop.prevent="isExpanded = true"></form-button>
        <form-button
          v-show="isExpanded"
          class="animate__fadeIn animate__faster"
          icon="chevron-down"
          text="theme.themeSettings.contract"
          @click.stop.prevent="isExpanded = false"></form-button>
      </div>

      <div
        v-for="(settingType, settingsTypeKey) of availableSettings"
        v-show="isExpanded"
        class="settings-group margin-xl-bottom animate__fadeIn animate__faster">
        <h3>{{ tt(`theme.themeSettings.${settingsTypeKey}.label`) }}</h3>
        <grid class="width-expand">
          <grid-column
            v-for="settingsField of settingType.fields"
            span="4"
            span-m="2"
            span-s="1"
            :uid="getUid(settingsField)">
            <div style="">
              <form-input
                v-model="value[settingsField]"
                class="width-expand"
                :type="settingType.fieldType"
                :label="`theme.themeSettings.${settingsTypeKey}.${settingsField}Label`"></form-input>

              <span>{{
                translate("theme.themeSettings.currentValueLegend", {
                  value: getVariableValue(settingsField),
                })
              }}</span>
            </div>
          </grid-column>
        </grid>
      </div>
    </div>
    <form-button
      v-show="isExpanded"
      class="animate__fadeIn animate__faster"
      icon="chevron-up"
      text="theme.themeSettings.contract"
      @click.stop.prevent="isExpanded = false"></form-button>
  </div>
</template>

<script>
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";

export default {
  mixins: [SimpleInputMixin],
  props: {
    iconTheme: {
      type: [String],
      default: "gray-4",
    },
    iconSize: {
      type: [Number],
      default: 1.2,
    },
    availableSettings: {
      type: Object,
      default: {
        colors: {
          fieldType: "text",
          fields: [
            "c-core-lead-1",
            "c-core-lead-2",
            "c-core-lead",
            "c-core-lead-4",
            "c-core-lead-5",
            "c-core-alt-1",
            "c-core-alt-2",
            "c-core-alt",
            "c-core-alt-4",
            "c-core-alt-5",
            "c-core-bg-1",
            "c-core-bg-2",
            "c-core-bg",
            "c-core-bg-4",
            "c-core-bg-5",
            "c-gray-1",
            "c-gray-2",
            "c-gray",
            "c-gray-4",
            "c-gray-5",
            "c-text",
            "c-inverse",
          ],
        },
        margins: {
          fieldType: "text",
          fields: [
            "margin-min",
            "margin-xs",
            "margin-s",
            "margin-m",
            "margin-l",
            "margin-xl",
            "margin-2xl",
            "margin-max",
          ],
        },
        fontSizes: {
          fieldType: "text",
          fields: [
            "font-min",
            "font-xs",
            "font-s",
            "font-m",
            "font-l",
            "font-xl",
            "font-2xl",
            "font-3xl",
            "font-max",
          ],
        },
      },
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    value: {
      get() {
        if (typeof this.modelValue !== "object") {
          return {};
        }
        return this.modelValue;
      },
      set(value) {
        this.$emit("field:changed", value);
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        Object.entries(newVal).forEach(([key, value]) => {
          if (typeof value !== "string") {
            return true;
          }

          if (value.trim() === "") {
            delete newVal[key];
          }
        });

        return newVal;
      },
      deep: true,
    },
  },
  methods: {
    getUid(seed) {
      return new Date().getTime + utilities.getUniqueNumber + seed;
    },
    getVariableValue(name) {
      if (utilities.isSSR()) {
        return "-";
      }

      let fullName = "--" + name;
      return window.getComputedStyle(document.documentElement).getPropertyValue(fullName);
    },

  },
};
</script>

<style scoped lang="scss"></style>
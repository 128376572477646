<template>
  <div class="select-media-container flex gap-m">
    <div class="preview padding-s flex flex-stretch border-thin-gray-2 cursor-pointer"
         @click.stop.prevent="startSelectFileProcess"
         style="height: 100px; width:150px;">
      <img class="object-cover max-size-parent width-expand"

           :src="currentMedia.thumbUrl"
           v-if="currentMedia && value">
      <div class="image-placeholder flex flex-center flex-middle bg-gray-4 width-expand" v-else>
        <icon size="3" icon="image" theme="bg"></icon>
      </div>

    </div>
    <div class="controls">
      <div class="upload-control flex flex-column flex-between "
           v-if="!isLoading"
           style="height: 100%;">
        <form-button theme="alt"
                     v-if="value"
                     @click.stop.prevent="startSelectFileProcess"
                     text="core.form.mediaSelect.change"
                     icon="refresh"></form-button>
        <form-button theme="danger"
                     v-if="value"
                     @click.stop.prevent="value = null"
                     text="core.form.mediaSelect.clear"
                     icon="x"></form-button>
        <form-button theme="success"
                     v-if="!value"
                     @click.stop.prevent="startSelectFileProcess"
                     text="core.form.mediaSelect.upload"
                     icon="cloud-upload"></form-button>
      </div>
      <div v-if="isLoading" class="flex flex-column flex-middle flex-center" style="height: 100%; min-width: 100px;">
        <spinner size="3"></spinner>
      </div>

      <file-input
        ref="fileUploadField"
        v-model="uploadFiles"
        class="hidden"
        label="file upload" />
    </div>

  </div>
</template>

<script>
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";
import useMediaManager from "@/client/extensions/composition/useMediaManager";
import asyncOperations from "@/client/extensions/composition/asyncOperations";

export default {
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    const mediaManager = useMediaManager(props, {});
    return { asyncOps, asyncOpsReady, asyncStatus, mediaManager };
  },
  mixins: [SimpleInputMixin],
  props: {

  },
  data() {
    return {
      mediaItems: this.mediaManager.items,
      uploadFiles: false,
      isLoading: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("field:changed", value);
        this.$emit("update:modelValue", value);
      },
    },
    currentMedia() {
      if (this.mediaItems && this.mediaItems.length > 0) {
        return this.mediaItems[0];
      }

      return false;
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if ( ! newVal) {
          return true;
        }
        this.mediaManager.setLimit(1);
        this.mediaManager.setFilter('id', newVal);
        this.mediaManager.refreshListItems();
        return newVal;
      },
      deep: true,
      immediate: true,
    },
    uploadFiles(newVal) {
      if (!newVal || newVal.length < 1) {
        return true;
      }
      this.uploadFilesByState();
    },
  },
  methods: {
    startSelectFileProcess() {
      try {
        this.$refs.fileUploadField.promptFileSelection();
      } catch (e) {
        warn("failed to focus file input", e);
      }
    },
    async uploadFilesByState() {
      let hasError = false;

      // basic validate the files
      for (const file of  this.uploadFiles) {
        if (file.size / 1000000 > config.mediaManager.maxFileSizeMB) {
          this.$s.ui.notification('media.manager.fileTooLargeNotification', 'error', {contentParams: {maxSizeMB:config.mediaManager.maxFileSizeMB }});
          return false;
        }
      }



      this.isLoading = true;

      let uploadResult;
      for (const file of this.uploadFiles) {
        uploadResult = await this.mediaManager.upload(file, {
          data: this.uploadData,
        });

        if (!uploadResult) {
          hasError = true;
        }
      }

      if (hasError) {
        this.$s.ui.notification("media.manager.uploadErrorOccurred", "error");
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
      this.value = uploadResult.id;
    },
  },
};
</script>

<style scoped lang="scss"></style>

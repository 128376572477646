<template>
  <div class="grid">
    <slot></slot>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  provide() {
    let injects = ["columns", "align", "grow", "gutter", "gutterS", "gutterM", "gutterL"];

    let res = {};
    injects.forEach((inject) => {
      res[inject] = computed(() => this[inject]);
    });

    return {
      ...res,
    };
  },
  props: {
    align: {
      type: String,
      default: "flex-start",
    },
    alignS: {
      type: [String, undefined],
      default: undefined,
    },
    alignM: {
      type: [String, undefined],
      default: undefined,
    },
    alignL: {
      type: [String, undefined],
      default: undefined,
    },
    justify: {
      type: String,
      default: "stretch",
    },
    justifyS: {
      type: [String, undefined],
      default: undefined,
    },
    justifyM: {
      type: [String, undefined],
      default: undefined,
    },
    justifyL: {
      type: [String, undefined],
      default: undefined,
    },
    columns: {
      type: Number,
      default: 12,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    gutter: {
      type: [Number, String],
      default: "xl",
    },
    gutterS: {
      type: [Number, String, undefined],
      default: undefined,
    },
    gutterM: {
      type: [Number, String, undefined],
      default: undefined,
    },
    gutterL: {
      type: [Number, String, undefined],
      default: undefined,
    },
  },
  data: function () {
    return {
      modifierMap: ["xl", "l", "m", "s", "xs"],
    };
  },
  computed: {
    computedJustifyContent() {
      return this.computeAlign()
    },
    computedJustifyContentS() {
      return this.computeAlign('s')
    },
    computedJustifyContentM() {
      return this.computeAlign('m')
    },
    computedJustifyContentL() {
      return this.computeAlign('l')
    },
    computedJustifyContentXl() {
      return this.computeAlign('xl')
    },
    computedAlignItems() {
      return this.computeJustify()
    },
    computedAlignItemsS() {
      return this.computeJustify('s')
    },
    computedAlignItemsM() {
      return this.computeJustify('m')
    },
    computedAlignItemsL() {
      return this.computeJustify('l')
    },
    computedAlignItemsXl() {
      return this.computeJustify('xl')
    },


    computedNegativeMargin() {
      return this.computeNegativeMargins();
    },
    computedNegativeMarginS() {
      return this.computeNegativeMargins('s');
    },
    computedNegativeMarginM() {
      return this.computeNegativeMargins('m');
    },
    computedNegativeMarginL() {
      return this.computeNegativeMargins('l');
    },
    computedNegativeMarginXl() {
      return this.computeNegativeMargins('xl');
    },
  },
  methods : {
    /**
     * Get a proprty which has mobile modifiers based on availability (desktop first)
     * @param name
     * @param modifier
     */
    getPropByPriority(name, modifier) {
      if (!modifier) {
        modifier = this.modifierMap[0];
      }
      // start with the default
      let baseName = name;
      let value = this[name];
      modifier = utilities.ucFirst(modifier);
      // iterate down to MODIFIER, overriding when found
      for (const possibleModifier of this.modifierMap) {
        let propName = baseName + utilities.ucFirst(possibleModifier);
        if (this[propName] !== undefined) {
          value = this[propName];
        }

        if (utilities.ucFirst(possibleModifier) == modifier) {
          break;
        }
      }
      // return the result
      return value;
    },
    computeNegativeMargins(breakPoint) {
      let targetGutter = this.getPropByPriority('gutter', breakPoint);

      if (typeof this.gutter === "string") {
        let safeGutter = `var(--margin-${targetGutter})`;
        let colGutter = `calc(-0.5 * ${safeGutter})`;
        return `${colGutter}`;
      } else {
        let safeGutter = parseInt(targetGutter);
        let colGutter = safeGutter / 2;
        return `-${colGutter}px`;
      }
    },
    computeJustify(breakPoint) {
      return this.getPropByPriority('justify', breakPoint);
    },
    computeAlign(breakPoint) {
      return this.getPropByPriority('align', breakPoint);
    },
  }
};
</script>

<style scoped lang="scss">
.grid {
  display: flex;

  flex-wrap: wrap;
  //gap implemented by children with padding
  //TODO: negative margin based on gutter
  margin: v-bind(computedNegativeMargin);

  justify-content: v-bind(computedJustifyContent);
  align-items: v-bind(computedAlignItems);

  @media (max-width: 1300px) {
    margin: v-bind(computedNegativeMarginXl);
    justify-content: v-bind(computedJustifyContentXl);
    align-items: v-bind(computedAlignItemsXl);
  }

  @media (max-width: 1024px) {
    margin: v-bind(computedNegativeMarginL);
    justify-content: v-bind(computedJustifyContentL);
    align-items: v-bind(computedAlignItemsL);
  }

  @media (max-width: 767px) {
    justify-content: v-bind(computedJustifyContentM);
    align-items: v-bind(computedAlignItemsM);
  }

  @media (max-width: 499px) {
    margin: v-bind(computedNegativeMarginS);
    justify-content: v-bind(computedJustifyContentS);
    align-items: v-bind(computedAlignItemsS);
  }
}
</style>

export default {
  props: {
    /**
     * Maximum height of the content when contracted
     * in pixles, number
     */
    maxHeight: {
      type: Number,
      default: 24,
    },
    /**
     * Theme name for the controls
     * i.e. bg, gray-5, lead-2, etc
     */
    controlsTheme: {
      type: String,
      default: "lead",
    },
    /**
     * Style ovbject for the controls
     * Useful for alignment, etc.,
     * OBJECT ONLY
     */
    controlsStyle: {
      type: Object,
      default: {},
    },
  },
  emits: [
    "spoiler:beforeExpand",
    "spoiler:expanded",
    "spoiler:beforeContract",
    "spoiler:contracted",
  ],
  data: function () {
    return {
      isExpanded: false,
      contentFullHeight: this.maxHeight,
      observer: false,
    };
  },
  computed: {
    shouldShowControls() {
      return this.contentFullHeight > Number(this.maxHeight);
    },
    currentContentMaxHeight() {
      if (this.isExpanded) {
        return this.contentFullHeight + "px";
      } else {
        return this.maxHeight + "px";
      }
    },
  },
  mounted() {
    this.watchContentHeight();
  },
  beforeUnmount() {
    this.stopWatchingContentHeight();
  },
  methods: {
    expand() {
      this.$emit("spoiler:beforeExpand", { spoiler: this, component: this });
      this.isExpanded = true;
      this.$emit("spoiler:expanded", { spoiler: this, component: this });
    },
    contract() {
      this.$emit("spoiler:beforeContract", { spoiler: this, component: this });
      this.isExpanded = false;
      this.$emit("spoiler:contracted", { spoiler: this, component: this });
    },
    updateFullContentHeightFromDom() {
      if (utilities.isSSR()) {
        return true;
      }
      let target = this.$refs["content"];
      if(target) {
        this.contentFullHeight = target.offsetHeight;
      }

    },
    watchContentHeight() {
      if (utilities.isSSR()) {
        return true;
      }
      let target = this.$refs["content"];
      if ( ! target) {
        return;
      }
      this.observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          // Height has changed, do something here
          this.updateFullContentHeightFromDom();
        }
      });
      this.observer.observe(target);
    },
    stopWatchingContentHeight() {
      try {
        let target = this.$refs["content"];
        this.observer.unobserve(target);
      } catch (e) {

      }
    },
  },
};

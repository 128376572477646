export default {
  props: {
    minimum: {
      type: [Number, String],
      default: 1,
    },
    maximum: {
      type: [Number, String],
      default: 9999999999,
    },
    stepSize: {
      type: Number,
      default: 1,
    },
    modelValue: {},
    class: {
      type: String,
      default: "",
    },
    list: {}, // prevent inheritence of list="" on element
  },
  data: function () {
    return {
      isFocused: false,
      localTargetValue: false,
    };
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        if (isNaN(this.modelValue)) {
          return this.minimum;
        }

        return this.modelValue;
      },
      set(value) {
        console.log("set value", value);
        if (this.isFocused) {
          this.$emit("update:modelValue", parseInt(value));
          return true;
        }

        if (value < parseInt(this.minimum)) {
          this.$emit("update:modelValue", parseInt(this.minimum));
          this.value = parseInt(this.minimum);
          return true;
        }

        if (value > parseInt(this.maximum)) {
          this.$emit("update:modelValue", parseInt(this.maximum));
          this.value = parseInt(this.maximum);
          return true;
        }

        if (value === this.maximum) {
          this.$emit("update:modelValue", value);
          return true;
        }

        // if value not moduleo 0 by step, and is not maximum, fall to closest step
        if (value % this.stepSize !== 0) {
          this.$emit(
            "update:modelValue",
            Math.round(value / this.stepSize) * this.stepSize
          );
          return true;
        }
        console.log("4");
        this.$emit("update:modelValue", value);
      },
    },
    inputAttrs() {
      let attrs = { ...this.$attrs };
      delete attrs.list;
      return attrs;
    },
    wrapperClass() {
      let result = [""];
      result.push(this.class.replace("uk-input", ""));
      return result;
    },
  },
  methods: {
    increment() {
      let newVal = +parseInt(this.value) + this.stepSize;

      if (newVal < this.maximum) {
        this.value = newVal;
      } else {
        this.value = this.maximum;
      }
    },
    decrement() {
      if (this.value > Number(this.minimum)) {
        this.value = +parseInt(this.value) - this.stepSize;
      }
    },
    handleFocusOut() {
      this.isFocused = false;
      this.enforceValueIntegrity();
    },
    enforceValueIntegrity() {
      console.log("enforece integrity");
      let oldVal = this.value;
      this.value = 0;
      this.value = oldVal;
    },
  },
  watch: {},
};

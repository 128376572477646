<template>
  <div>
    <modal
      ref="editModal"
      :show="show"
      :title="'core.quickEditor.editorModalTitle'"
      @modal:closed="modalClosedHandler">
      <template #default>
        <base-form
          ref="editForm"
          v-bind="formProps"
          v-model="formValue"
          @form:submitEnd="formHandler"></base-form>
      </template>
      <template #footer="{modal}">
        <div class="flex flex-center gap-m width-expand max-width-400 margin-auto">
          <form-button @click="modal.closeModal('reject', true)" theme="inverse" icon="x" text="core.cancel"></form-button>
          <form-button @click="$refs.editForm.handleSubmit()" theme="lead" icon="check" text="core.save"></form-button>
        </div>
      </template>

    </modal>
  </div>
</template>

<script>
import { isRef, unref } from "vue";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    formProps: {
      type: Object,
      default: {},
    },
    targetData: {
      type: Object,
      default: {},
    },
    successCallback: {
      type: Function,
      default: () => {},
    },
    errorCallback: {
      type: Function,
      default: () => {},
    },
    cancelCallback: {
      type: Function,
      default: () => {},
    },
    generalCallback: {
      type: Function,
      default: () => {
        console.log("defautlt");
      },
    },
    getTargetData: {
      type: [Function, Boolean],
      default: false,
    },
    resetData: {
      type: Function,
      default: () => {},
    }
  },
  data: function () {
    let finalLocalData = isRef(this.targetData)
      ? unref(this.targetData)
      : this.targetData;

    return {
      localData: { ...this.getTargetData() },
    };
  },
  computed: {
    formValue: {
      set(val) {
        return true;
      },
      get() {
        return { ...this.localData };
      },
    },
  },
  watch: {
    targetData: {
      handler(newVal) {
        this.localData = this.getTargetData();
      },
      immediate: false,
    },
    show: {
      handler(newVal) {
        if (this.getTargetData) {
          this.localData = this.getTargetData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    formHandler(arg) {
      const { component, data, isError, result } = arg;
      if (isError) {
        this.errorCallback(arg);
      } else {
        this.successCallback(arg);
      }
      this.generalCallback(arg);
    },
    submitEdit() {},
    cancel () {
      this.$refs['editModal'].close(null, true)
    },
    modalClosedHandler(a, b) {
      this.resetData();
      if (a.context && a.context === "reject") {
        this.cancelCallback();
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <i class="we-icon icon" :title="finalIconAlt" :style="computedStyle" />
  <!-- <div class="icon-halo we-icon-halo" v-if=" halo" >
          <i class="we-icon icon we-icon--halo icon--halo" :title="finalIconAlt"/>
      </div>
      -->
</template>

<script>
import IconMixin from "@/client/extensions/mixins/baseComponents/html/icon.js";

export default {
  /**
   * Api:
   * icon - name of the icon file (no extension)
   * set - name of the folder set in the assets folder. default is based on config
   * alt - alt for the icon, uses a generic default, uses safeTranslate
   * ratio - size compared to current font size (em). default is slightly larger than text
   * height & width: overrides ratio, if provided
   * color: overrides color, default is currentcolor (css)
   */
  mixins: [IconMixin],
  props: {},
  data: function () {
    return {
      haloThemeMap: {
        default: "gray-1",
        lead: "gray-1",
        "lead-2": "gray-1",
        alt: "gray-1",
        alt2: "gray-1",
        inverse: "gray-1",
        bg: "inverse",
        "bg-4": "inverse",
        "bg-5": "inverse",
        "comp-lead": "gray-1",
        "comp-lead-2": "gray-1",
        "comp-alt": "gray-1",
        "comp-alt-2": "gray-1",
        "gray-1": "gray-5",
        "gray-2": "gray-5",
        gray: "inverse",
        "gray-3": "inverse",
        "gray-4": "gray-1",
        "gray-5": "gray-1",
        success: "gray-1",
        "success-inverse": "success",
        warning: "gray-1",
        "warning-inverse": "success",
        danger: "gray-1",
        "danger-inverse": "success",
        error: "gray-1",
        "error-inverse": "success",
      },
    };
  },

  computed: {
    computedStyle() {
      /*
       height: v-bind(heightProperty);
       width: v-bind(widthProperty);
       flex: v-bind(flexProperty);
       background-color: v-bind(colorProperty);
       -webkit-mask: v-bind(finalIconMaskProperty);
       mask: v-bind(finalIconMaskProperty);
       */

      /*
       -webkit-mask-size: contain;
       mask-size: contain;
       */
      return {
        height: this.heightProperty,
        width: this.widthProperty,
        flex: this.flexProperty,
        backgroundColor: this.colorProperty,
        "-webkit-mask": this.finalIconMaskProperty,
        mask: this.finalIconMaskProperty,
        "-webkit-mask-size": "contain",
        maskSize: "contain",
      };
    },
  },
};
</script>

<style scoped lang="scss">

</style>

<template>
  <div
    class="large-option padding-m-vertical padding-l-horizontal flex flex-stretch gap-l"
    :class="{ selected: !!selected }"
    @click="$emit('large-option:selected', value)">
    <div class="start flex gap-l flex-middle">
      <form-input type="checkbox"
                  @click.prevent=""
                  :checked="selected ? true : null"></form-input>
      <div class="text width-expand">
        <slot name="title">
          <h6 v-if="title" class="block margin-remove">{{ translateTitleCase(title) }}</h6>
        </slot>
        <slot name="text">
          <span v-if="text" class="text-s">{{ translateTitleCase(text) }}</span>
        </slot>

      </div>
    </div>
    <div
      v-if="endText || $slots['endText']"
      class="end flex gap-l flex-middle"
      style="margin-inline-start: auto">
      <slot name="endText">
        {{ translate(endText) }}
      </slot>

    </div>
  </div>
</template>

<script>
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";

export default {
  mixins: [SimpleInputMixin],
  props: {
    title: {
      type: [Boolean, String],
      default: false,
    },
    text: {
      type: [Boolean, String],
      default: false,
    },
    endText: {
      type: [Boolean, String],
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
  },
  emits: ["large-option:selected"],
};
</script>

<style scoped lang="scss">
.large-option {
  border: 1px solid var(--c-gray);
  margin-bottom: var(--margin-m);
  cursor: pointer;
  background: var(--c-gray-1);
  border-radius: var(--border-radius-s);

  &:last-of-type {
    margin-bottom: 0;
  }

  &.selected {
    border: 1px solid var(--c-lead);
  }

  &:hover {
    border: 1px solid var(--c-gray-5);
  }
}
</style>

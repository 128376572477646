export default {
  props: {
    modelValue: {},
    for: {
      type: String,
      default: "",
    },
    showSlot: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {};
  },
  computed: {
    labelFor() {
      return this.for;
    },
  },
};

<template>
  <form-input
    v-bind="safeAttrs"
    v-model="value"
    :label="false"
    type="select2"
    :list="internalLocaleList"
    :wrapper-margins="false"
    :auto-input-styleing="false">
  </form-input>
</template>

<script>
import LocaleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/localeInput.js";
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";

export default {
  mixins: [SimpleInputMixin, LocaleInputMixin],

  props: {
    translateList: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <modal
    v-if="show"
    ref="videoModal"
    :tight="true"
    size="auto"
    :title="false"
    :show-footer="false"
    :show="show"
    @modal:closed="show = false">
    <div
      style=""
      class="video-container bg-gray-1 border-gray-s border-radius-s box-shadow-4">
      <ratio-container class="border-radius-s" :ratio="16 / 9">
        <iframe
          class="border-radius-s"
          loading="lazy"
          width="100%"
          height="100%"
          :src="`https://www.youtube.com/embed/${videoId}?autoplay=${iframeAutoPlayValue}`"
          title="YouTube video player"
          allow="accelerometer; clipboard-write; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </ratio-container>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    videoId: {
      type: String,
      default: false,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    iframeAutoPlayValue() {
      return this.autoPlay ? "1" : "0";
    },
  },
  methods: {
    showModal() {
      this.$refs["videoModal"].openModal();
    },
    hideModal() {
      this.$refs["videoModal"].hideModal();
    },
  },
};
</script>

<style scoped lang="scss">
.video-container {
  width: 90vw;
  max-width: calc(16 / 9 * 0.75 * 100vh);
  margin-inline: auto !important;

  @media (max-width: 767px) {
  }
}
</style>

<template>
  <div
    class="wrapper flex flex-gap flex-middle relative"
    :class="computedWrapperClass">
    <input
      v-bind="inputAttrs"
      ref="inputElement"
      v-model="value"
      :type="'color'"
      style="display: none"
      class="field__element"
      @focus="isFocused = true"
      @blur="isFocused = false" />
    <div
      class="preview field__element field__element--color width-expand flex flex-gap flex-middle"
      @click.prevent="$refs.inputElement.click()">
      <block
        class="field__element-color-preview margin-s-inline-start animate__fadeIn animate__faster"
        :style="{
          'background-color': value ? value : 'transparent',
          display: value ? 'inline-block' : 'none',
        }"></block>
      <input
        v-model="value"
        :placeholder="placeholder"
        class="field__element-color-input-inner" />
    </div>
  </div>
</template>

<script>
import ColorInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/colorInput.js";

export default {
  mixins: [ColorInputMixin],
  props: {
    class: {
      default: "",
    },
    placeholder: {
      default: "test",
    },
  },
  computed: {
    computedWrapperClass() {
      let localClass = this.class;
      let exploded = localClass.split(" ");
      let result = exploded.filter((className) => {
        return className !== "field__element";
      });

      return result.join(" ");
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="wrapper flex flex-gap flex-middle relative" :class="computedWrapperClass">
    <div
      class="relative preview background-cover flex-middle flex-center cursor-pointer"
      @click.prevent.stop="">
      <div
        v-show="isValueAYoutubeVideoId"
        class="video-preview absolute-cover">
        <iframe :src="'https://www.youtube.com/embed/'+value" style="object-fit: contain; max-height: 100%; max-width: 100%;"></iframe>
      </div>
      <icon v-show="!isValueAYoutubeVideoId" icon="image" size="3" theme="gray"></icon>
    </div>

    <div class="actions flex-column flex-between gap-m">
      <div class="input-wrapper">
        <form-input
          v-bind="YoutubeVideoIdInputAttrs"
          ref="inputElement"
          v-model="value"
          theme-style="minimal"
          icon="link"
          style="max-width: 100%"
          :type="'text'"
          :wrapper-padding="false"
          placeholder="core.form.youtubeVideoId.urlFieldPlaceholder"
          class="url-input field__element"
          :label="false"/>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";

export default {
  mixins: [SimpleInputMixin],
  props: {
    class: {
      default: "",
    },
    placeholder: {
      default: "test",
    },
  },
  setup(props) {},
  data() {
    return {

    };
  },
  computed: {
    isValueAYoutubeVideoId() {
      return this.value && typeof this.value === 'string' && !this.value.startsWith('https://') && this.value.length > 8;
    },
    computedWrapperClass() {
      let localClass = this.class;
      let exploded = localClass.split(" ");
      let result = exploded.filter((className) => {
        return className !== "field__element";
      });

      return result.join(" ");
    },
    YoutubeVideoIdInputAttrs() {
      let result = { ...this.inputAttrs };
      delete result.legend;
      return result;
    },
  },
  methods: {
    isUrl(url) {
      try {
        return url.startsWith('https://')
      } catch(e) {
        console.log(e, typeof url);
      }

    },
    getYoutubeIdFromUrl(url){
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    },
  },
  watch: {
    value(newVal) {
      if ( ! this.isUrl(newVal)) {
       return true;
      }
      let videoId = this.getYoutubeIdFromUrl(newVal);

      if (videoId) {
        this.$nextTick(() => {
          this.value = videoId;
        });
      }
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
.preview {
  --image-url-preview-width: 200px;
  width: var(--image-url-preview-width);
  flex: 0 0 var(--image-url-preview-width);
  height: calc(var(--image-url-preview-width) * 9 / 16);
  border-inline-end: 1px solid var(--c-gray-2);
  background-color: var(--c-gray-1);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1) inset;
  border-radius: var(--border-radius-sharp) 0 0 var(--border-radius-sharp);
  @media screen and (max-width: 767px) {
    width: 150px;
    flex: 0 0 150px;
    height: calc(150px * 9 / 16);
  }
}

.video-preview {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.url-input {
  padding-inline: var(--margin-s);
}
</style>

<template>
  <modal
    v-if="show"
    ref="imageModal"
    :tight="true"
    size="auto"
    :title="false"
    :show-footer="false"
    :show="show"
    @modal:closed="show = false">
    <div
      style=""
      class="image-container bg-gray-1 border-gray-s border-radius-s box-shadow-4 flex-middle flex-center">
      <img :src="src" :alt="alt" style="max-width: 100%;max-height: 100%; object-fit: contain;" />
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: false,
    },
    alt: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    imageAlt() {
      if (this.alt) {
        return this.alt;
      }

      return "";
    },
    iframeAutoPlayValue() {
      return this.autoPlay ? "1" : "0";
    },
  },
  methods: {
    showModal() {
      this.$refs["imageModal"].openModal();
    },
    hideModal() {
      this.$refs["imageModal"].hideModal();
    },
  },
};
</script>

<style scoped lang="scss">
.image-container {
  width: 90vw;
  max-width: calc(16 / 9 * 0.75 * 100vh);
  margin-inline: auto !important;

  @media (max-width: 767px) {
  }
}
</style>

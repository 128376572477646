let importGlobalComponents = async () => {
  let list = {};
  let frameworkPaths = [];
  let appName = process.env.VUE_APP_APPLICATION_NAME;
  let context;

  // TODO: import from applet overrides, if exist, skip whats already included
  //context = require.context('@/overrides/client/applications/', true, /^\.\/.*\/components\/base.*\.vue$/);
  context = require.context(
    "@/",
    true,
    /overrides\/client\/applications\/.*\/components\/base.*\.vue/
  );

  context.keys().forEach((key) => {
    let componentName = key.split("/").pop().replace(".vue", "");
    let componentAppName =
      key.replace("./overrides/client/applications/", "").split("/")[0] || "";
    let relativePath = key.replace(
      "./overrides/client/applications/" + appName + "/components/base/",
      ""
    );

    // skip paths not in app
    if (componentAppName !== appName) {
      return true;
    }

    // if the component belongs to a framework, only import it if the framework components are required
    if (
      relativePath.startsWith("frameworks") &&
      !config.style.frameworkComponents.includes(relativePath.split("/")[1])
    ) {
      return true;
    }

    // if the component is already loaded it was overridden and can be used. Importing it will delete the override
    if (list.hasOwnProperty(componentName)) {
      return true;
    }

    list[componentName] = context(key).default || context(key);
  });

  context = require.context(
    "@/client/applications/",
    true,
    /^\.\/.*\/components\/base.*\.vue$/
  );
  context.keys().forEach((key) => {
    let componentName = key.split("/").pop().replace(".vue", "");
    let componentAppName = key.replace("./", "").split("/")[0] || "";
    let relativePath = key.replace("./" + appName + "/components/base/", "");

    // skip paths not in app
    if (componentAppName !== appName) {
      return true;
    }

    // if the component belongs to a framework, only import it if the framework components are required
    if (
      relativePath.startsWith("frameworks") &&
      !config.style.frameworkComponents.includes(relativePath.split("/")[1])
    ) {
      return true;
    }

    // if the component is already loaded it was overridden and can be used. Importing it will delete the override
    if (list.hasOwnProperty(componentName)) {
      return true;
    }

    list[componentName] = context(key).default || context(key);
  });

  // import from core overrides,

  try {
    //let context = require.context('@/overrides/client/components/base/', true, /\.vue/);
    let context = require.context(
      "@/",
      true,
      /\/overrides\/client\/components\/base\/.*\.vue/
    );

    context.keys().forEach((key) => {
      let componentName = key.split("/").pop().replace(".vue", "");

      if (list.hasOwnProperty(componentName)) {
        return true;
      }

      list[componentName] = context(key).default || context(key);
    });
  } catch (e) {}

  // now import from core application. Skip components that are already loaded from overrides, and design framework compoentns ('./frameworks/...')
  context = require.context("@/client/components/base/", true, /\.vue/);
  context.keys().forEach((key) => {
    let componentName = key.split("/").pop().replace(".vue", "");

    // if the component belongs to a framework, only import it if the framework components are required
    if (
      key.startsWith("./frameworks") &&
      !config.style.frameworkComponents.includes(key.split("/")["2"])
    ) {
      return true;
    }

    // if the component is already loaded it was overridden and can be used. Importing it will delete the override
    if (list.hasOwnProperty(componentName)) {
      return true;
    }

    list[componentName] = context(key).default || context(key);
  });

  return list;
};

// it is actually not required for this to be a promise. But there's also no need to change it
export default new Promise(async (resolve) => {
  let list = await importGlobalComponents();

  let plugin = {
    install(app) {
      for (const [key, value] of Object.entries(list)) {
        let finalName = key.charAt(0).toUpperCase() + key.slice(1);
        app.component(finalName, value);
      }
    },
  };

  resolve(plugin);
});

<template>
  <input ref="actualFileInput"
         type="file"
         v-bind="inputAttrs"
         @change="changeHandler" />
</template>

<script>
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";

export default {
  mixins: [SimpleInputMixin],
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    changeHandler(event) {
      this.value = event.target.files;
    },
    promptFileSelection() {
      if ( ! this.$refs.actualFileInput) {
        return false;
      }
      try {
        this.$refs.actualFileInput.click();
        return true;
      } catch(e) {
        notice(e);
        return false;
      }
    }
  },
};
</script>

<style scoped lang="scss"></style>

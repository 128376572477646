<template>
  <a v-if="isExternalLink" v-bind="$attrs" :href="to" target="_blank">
    <slot />
  </a>
  <router-link v-else
               v-bind="$attrs"
               :to="to"
               :parentActive="parentActive"
               :exactActive="exactActive"
               :class="extraClass">
    <slot v-bind="{...$props, parentActive, exactActive }"></slot>
  </router-link>
</template>

<script>
import { RouterLink, useLink } from "vue-router";

export default {
  name: "SaffronLink",
  inheritAttrs: false,
  props: {
    ...RouterLink.props,
    inactiveClass: String,
  },
  setup(props) {
    return useLink(props);
  },
  data: function () {
    return {};
  },
  computed: {
    isExternalLink() {
      return typeof this.to === "string" && this.to.startsWith("http");
    },
    extraClass() {
      let routeValue = this.$router.currentRoute.value;

      if (!this.route.name) {
        return "";
      }

      if (!routeValue || !routeValue.meta || !routeValue.meta.saffronPageParents) {
        return "";
      }

      let parentNames = routeValue.meta.saffronPageParents;

      if ( ! parentNames) {
        return "";
      }
      if (parentNames.includes(this.route.name)) {
        return config.router.linkActiveClass;
      } else {
        return "";
      }
    },
    parentActive() {
      let routeValue = this.$router.currentRoute.value;
      let parentNames = routeValue.meta.saffronPageParents;

      if ( ! parentNames) {
        return false;
      }
      if (parentNames.includes(this.route.name)) {
        return true;
      } else {
        return false;
      }
    },
    exactActive () {
      let res = this.$router.currentRoute.value.name == this.route.name;
      return res;
    }
  },

  mounted() {},
};
</script>

<style scoped lang="scss"></style>

export default {
  props: {
    theme: {
      type: String,
      default: "lead",
    },
    size: {
      type: String,
      default: "m",
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
    iconEnd: {
      type: [String, Boolean],
      default: false,
    },
    text: {
      type: [String, Boolean, Number],
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    border: {
      type: [Boolean, String, Number],
      default: false,
    },
    borderTheme: {
      type: [Boolean, String],
      default: false, // false: use inherit contrast conversion
    }
  },
  data: function () {
    return {
      defaultColorMap: {
        color: "c-bg",
        "border-color": "c-" + this.theme,
        "background-color": "c-" + this.theme,
      },
      themeContrastMap: {
        default: "gray-1",
        lead: "gray-1",
        "lead-2": "gray-1",
        alt: "gray-1",
        alt2: "gray-1",
        inverse: "gray-1",
        bg: "inverse",
        "bg-4": "inverse",
        "bg-5": "inverse",
        "comp-lead": "gray-1",
        "comp-lead-2": "gray-1",
        "comp-alt": "gray-1",
        "comp-alt-2": "gray-1",
        "gray-1": "gray-5",
        "gray-2": "gray-5",
        gray: "inverse",
        "gray-3": "inverse",
        "gray-4": "gray-1",
        "gray-5": "gray-1",
        success: "gray-1",
        "success-inverse": "success",
        warning: "gray-1",
        "warning-inverse": "success",
        danger: "gray-1",
        "danger-inverse": "success",
        error: "gray-1",
        "error-inverse": "success",
      },
      themeColorMap: {
        bg: {
          color: "c-inverse",
          "border-color": "c-inverse",
        },
      },
      contentGapMap: {
        l: "m",
        m: "s",
        s: "s",
      },
    };
  },
  computed: {
    hasContent() {
      if (this.icon) {
        return true;
      }

      if (this.iconEnd) {
        return true;
      }

      if (this.text) {
        return true;
      }

      if (this.$slots.default) {
        return true;
      }

      return false;
    },
    colorTemplate() {
      let override = {};
      if (this.themeColorMap.hasOwnProperty(this.theme)) {
        override = { ...this.themeColorMap[this.theme] };
      }
      return { ...this.defaultColorMap, ...override };
    },
    computedStyle() {
      let style = {};
      for (const [key, value] of Object.entries(this.colorTemplate)) {
        //style[key] = `var(--${value})`;
      }

      let borderWidthPx = 2;
      if (typeof this.border === 'number') {
        borderWidthPx = this.border + 'px';
      }

      if (typeof this.border === 'string') {
        borderWidthPx = this.border;
      }

      let borderColor = this.borderTheme ? this.borderTheme : this.contrastColor;
      if (this.border) {
        style['border'] = `${borderWidthPx} solid var(--c-${borderColor})`
      }
      return style;
    },
    dynamicClass() {
      let res = ["badge--" + this.size, "badge--" + this.theme];
      if (this.hasContent) {
        res.push("badge--has-content");
      } else {
        res.push("badge--no-content");
      }

      if (this.round) {
        res.push("badge--round");
      }

      return res;
    },
    contrastColor() {
      if (!Object.keys(this.themeContrastMap).includes(this.theme)) {
        return "bg";
      }

      return this.themeContrastMap[this.theme];
    },
    contentGapClass() {
      if (!Object.keys(this.contentGapMap).includes(this.size)) {
        return "gap-s";
      }
      return "gap-" + this.contentGapMap[this.size];
    },
  },
};

<template>
  <ul class="simple-value-list svl">
    <li class="margin-m-bottom" v-for="(item, index) of finalList">
      <slot name="item" v-bind="{ item }">
        <span class="svl__label inline-block text-bold block min-width-150">
          <slot name="label" v-bind="{ item }">
            <slot :name="item.labelKey" v-bind="{ item }">
              {{ translate(item.label) }}:
            </slot>
          </slot>
        </span>

        <span class="svl__value">
          <slot name="value" v-bind="{ item }">
            <slot :name="item.key" v-bind="{ item }">
              <span v-if="!item.link && !item.internalLink">{{ item.value }}</span>
              <a v-if="item.link" :href="item.link">{{ item.value }}</a>
              <saffron-link v-if="item.internalLink" :to="item.internalLink">{{
                item.value
              }}</saffron-link>
            </slot>
          </slot>
        </span>
      </slot>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  data: function () {
    return {};
  },
  computed: {
    finalList() {
      let result = [];
      this.list.forEach((item, index) => {
        let internalLink = item.routerLink ? item.routerLink : false;
        if (!internalLink) {
          internalLink = item.saffronLink ? item.saffronLink : false;
        }

        if (!internalLink) {
          internalLink = item.internalLink ? item.internalLink : false;
        }
        result.push({
          index: index,
          value: item.value,
          label: item.label,
          key: (item.key ? item.key : index) + "-value",
          labelKey: (item.labelKey ? item.labelKey : index) + "-label",
          link: item.link ? item.link : false,
          internalLink: internalLink,
        });
      });
      return result;
    },
  },
};
</script>

<style scoped lang="scss"></style>

export default {
  props: {
    modelValue: {},
    list: {}, // prevent inheritence of list="" on element
  },
  data: function () {
    return {};
  },
  emits: ["update:modelValue"],
  computed: {
    inputAttrs() {
      let attrs = { ...this.$attrs };
      delete attrs.list;
      return attrs;
    },
  },
};

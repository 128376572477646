<template>
  <component
    :is="'default-notification-item'"
    :action="action"
    v-bind="$props"
    :notification="safeNotification">
  </component>
</template>

<script>
import notificationItem from "@/client/extensions/mixins/baseComponents/notificationItem";

export default {
  mixins: [notificationItem],
  props: {
    icon: {
      type: [String, Boolean],
      default: "user",
    },
  },
  data: function () {
    return {};
  },
  computed: {
    action() {
      return {
        type: "callback",
        ctaComponent: "form-button",
        ctaProps: {
          iconEnd: "chevron-inline-end",
          theme: "alt",
          text: "notifications.types.test.ctaText",
          size: "s",
        },
        handler: () => {
          this.$s.ui.notification("TEST HANDLER");
        },
      };
    },
  },
  methods: {
    getSafeNotificationData(notificationData) {
      let res = {};

      if (notificationData && typeof notificationData === "object") {
        res = { ...notificationData };
      }

      // example: make bogle
      if (!res.bogle) {
        res.bogle = "unknown";
      }

      return res;
    },
  },
};
</script>

<style scoped lang="scss"></style>

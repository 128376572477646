<template>
  <div v-if="!closed" ref="alertWrapper" class="alert animate--fast" :class="alertClass">
    <!-- title slot -->
    <span v-if="title || $slots['title']" class="alert__title">
      <slot name="title" v-bind="{ alert: this }">
        <span>{{ translateTitleCase(title) }}</span>
      </slot>
    </span>

    <!-- close icon -->
    <icon
      v-if="showClose"
      class="alert__close-icon"
      size="1.8"
      icon="x"
      @click="close"></icon>

    <!-- main slot -->
    <div v-if="content || $slots['default']" class="alert__content">
      <slot name="default" v-bind="{ alert: this }">
        {{ safeTranslate(content) }}
      </slot>
    </div>
  </div>
</template>

<script>
import AlertMixin from "@/client/extensions/mixins/baseComponents/alert.js";

export default {
  mixins: [AlertMixin],
  props: {
    theme: {
      type: [String, Boolean],
      default: false,
    },
    animation: {
      type: [String, Boolean],
      default: "fadeInUpSmall",
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
    content: {
      type: [String, Boolean],
      default: false,
    },
  },
  emits: [
    "alert:closing",
    "alert:beforeClose",
    "alert:afterClose",
    "alert:beforeOpen",
    "alert:afterOpen",
  ],
  data: function () {
    return {
      closed: false,
      themeTypeMap: {
        primary: "lead",
        secondary: "gray",
        danger: "danger",
        warning: "warning",
        success: "success",
      },
    };
  },
  computed: {
    alertClass() {
      let result = [];
      if (this.theme) {
        result.push("alert--" + this.theme);
      } else {
        result.push("alert--" + this.themeTypeMap[this.type]);
      }

      if (this.animation) {
        result.push("animate__" + this.animation);
      }

      return result;
    },
  },
  created() {},
  methods: {
    close() {
      this.$emit("alert:closing");
      this.$emit("alert:beforeClose");
      this.closed = true;
      this.$emit("alert:afterClose");
    },
    open() {
      this.$emit("alert:beforeOpen");
      this.closed = false;
      this.$emit("alert:afterOpen");
    },
  },
};
</script>

<style scoped lang="scss"></style>
